import "./createmode.css";
import {useEffect, useState} from "react";
import {PiArrowFatLeftFill, PiArrowFatRight, PiArrowFatRightFill, PiArrowRightFill, PiArrowsCounterClockwise} from "react-icons/pi";
import {createTracker, getFeynmanTrackers, updateFeynmanTracker} from "../../api/reference_tracker";

export function FeynmanMethod({ mode }) {
    const [question, setQuestion] = useState("");
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(0);
    const [id, setID] = useState(null);
    const [allFeynmanTrackers, setAllFeynmanTrackers] = useState([]);
    const [questionFocus, setQuestionFocus] = useState(false);
    const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        if (mode === "edit") {
            // get old Feynmans
        }
        getAllFeynmanMethods();
    },[])

    useEffect(() => {
        console.log(id)
    },[id]);

    const getAllFeynmanMethods = async () => {
        const data = await getFeynmanTrackers();
        setAllFeynmanTrackers([...data])
    }

    const createNewLevel = () => {
        const len = levels.length;
        setLevels([...levels, {
            level: levels.length,
            description: "",
            QAs: [{question: "question", answer: "answer"}]
        }])
        setCurrentLevel(len);
    }

    const addQA = (i) => {
        let levelsCopy = [...levels];
        const qasLength = levelsCopy[i]['QAs'].length;
        levelsCopy[i]['QAs'] = [...levelsCopy[i]['QAs'], {question: `question-${qasLength}`, answer: `answer-${qasLength}`}];
        setLevels([...levelsCopy]);
    }

    const incrementCurrentLevel = () => {
        if (currentLevel < levels.length - 1) {
            setCurrentLevel(currentLevel+1);
        }
    }

    const decrementCurrentLevel = () => {
        if (currentLevel > 0) {
            setCurrentLevel(currentLevel - 1);
        }
    }

    const updateQA = (QAIndex, e) => {
        let levelsCopy = [...levels];
        levelsCopy[currentLevel]['QAs'][QAIndex] = {...levelsCopy[currentLevel]['QAs'][QAIndex], [e.target.name]: e.target.value};
        setLevels([...levels]);
        autoResizeTextArea();
    }

    const submitTracker = async () => {
        if (!id && question.length > 0 && question.includes("?") && levels.length > 0) {
            await createTracker("feynman", {
                startDate: new Date(),
                lastModified: new Date(),
                levels: levels,
                question: question
            }, "json").then((obj) => selectExistingReference(obj));
                // obj.status === 201 ? setID(obj._id) : alert("Failed to create tracker."));
        } else if (id && question.length > 0 && question.includes("?") && levels.length > 0) {
            console.log("Updating...");
            await updateFeynmanTracker(id, {question:question, levels:levels, startDate: startDate, lastModifiedDate: new Date()}).then((status) => status !== 201 && alert("Failed to update tracker."))
        } else {
            alert("Please make sure the tracker has a main question and at least one level.");
        }
    }

    const updateDescription = (e: any) => {
        let levelsCopy = [...levels];
        levelsCopy[currentLevel] = {...levelsCopy[currentLevel], description: e.target.value};
        setLevels([...levelsCopy]);
    }

    const selectExistingReference = (tracker: any) => {
        setID(tracker._id);
        setQuestion(tracker.question);
        setLevels(tracker.levels);
        setStartDate(tracker.startDate);
    }

    const autoResizeTextArea = () => {
        const currentTextArea = document.getElementsByClassName('auto-resize');
        // @ts-ignore
        for (let element of currentTextArea) {
            element.style.height = 'auto';
            element.style.height = element.scrollHeight + 'px';
        }
    }

    return (
        <div className="feynman-method">
            {currentLevel > 0 && levels.length > 0 &&
                <div className="level-arrow left">
                    <PiArrowFatLeftFill
                        style={{cursor:"pointer"}}
                        color={"var(--link-grey)"}
                        onClick={() => decrementCurrentLevel()}
                    />
                </div>
            }
            {levels.length - 1 > currentLevel && levels.length > 0 &&
                <div className="level-arrow right">
                    <PiArrowFatRightFill
                        style={{cursor:"pointer"}}
                        color={"var(--link-grey)"}
                        onClick={() => incrementCurrentLevel()}
                    />
                </div>
            }
            <div className="question"
                 // onBlur={() => setQuestionFocus(false)}
            >
                <div
                    className="heading"
                    tabIndex={0}
                >Question</div>
                <input
                    name={"question"}
                    onChange={(e) => setQuestion(e.target.value)}
                    onFocus={() => setQuestionFocus(true)}
                    value={question}
                />
                {questionFocus && !id && question.length > 0 && allFeynmanTrackers.length > 0 && <div className="search-results">{allFeynmanTrackers.filter((item) => item.question.toLowerCase().includes(question.toLowerCase())).map((tracker, i) => {
                    return <div onClick={() => selectExistingReference(tracker)} key={i} tabIndex={0}
                                style={{cursor:"pointer"}}
                        className="search-suggestion">
                        {tracker.question}
                    </div>
                })}</div>}
            </div>
            <div className="levels">
                <div className="heading">
                    Levels <button onClick={() => createNewLevel()}>+</button>
                </div>
                <div className="container">
                    {levels.length > 0 && currentLevel >= 0 &&
                        <>
                            <div className="digit">{levels[currentLevel].level}</div>
                            <div>
                                <button
                                    onClick={() => addQA(currentLevel)}
                                >+</button>
                            </div>
                            {levels.filter((level, index) => index === currentLevel).map((level) => {
                                return <div className="level">
                                    <div className="heading">Description</div>
                                    {<div className="description">
                                        <textarea id='auto-resize' autoCorrect={"false"} spellCheck={"false"} value={level.description} onChange={(e) => updateDescription(e)} />
                                    </div>}
                                    <div className="qas">
                                    {level.QAs.map((qa, i) => {
                                        return <div className="qa" key={i}>
                                            <div className="qa-sig">Q</div>
                                            <textarea
                                                spellCheck={"false"}
                                                className={"question auto-resize"}
                                                name="question"
                                                value={qa.question}
                                                onChange={(e) => updateQA(i, e)}
                                            />
                                            <div className="qa-sig">A</div>
                                            <textarea
                                                spellCheck={"false"}
                                                className={"answer auto-resize"}
                                                name="answer"
                                                value={qa.answer}
                                                onChange={(e) => updateQA(i, e)}
                                            />
                                        </div>
                                    })}</div>
                                </div>
                            })
                    }</>}

                </div>
            </div>
            <div className="submit-feynman">
                <button onClick={() => submitTracker()}>
                    submit
                </button>
            </div>
        </div>
    )
}

