import "./createmode.css";
import {useEffect, useState, useRef} from "react";
import {PiArrowFatLeftFill, PiArrowFatRight, PiArrowFatRightFill, PiArrowRightFill, PiArrowsCounterClockwise} from "react-icons/pi";
import {fetchReferences, getReference} from "../../api/reference_tracker";
import {FeynmanMethod} from "./FeynmanMethod";
import {CardWorkspace} from "./card_workspace/CardWorkspace";
import {SummaryWorkspace} from "./summary_workspace/SummaryWorkspace";

export function CreateMode() {
    const [mode, setMode] = useState("cards");
    const [references, setReferences] = useState([]);
    const [selectedReference, selectReference] = useState(null);
    const [segments, setSegments] = useState([]);
    const [selectedSegment, selectSegment] = useState(null);
    const [resetKey, setResetKey] = useState(0);
    const [method, setMethod] = useState("none");
    const [topic, setTopic] = useState("");

    useEffect(() => {
        retrieveReferences();
    },[])

    useEffect(() => {
        if (selectedReference && selectedReference.content) {
            setResetKey((resetKey) => resetKey + 1);
            setSegments([...selectedReference.content])
        }

    },[selectedReference])

    const retrieveReferences = async () => {
        const data = await fetchReferences();
        if (data.length > 0) {
            data.sort((a, b) => a.title.localeCompare(b.title))
            setReferences(data);
            if (method === "reference") fetchFullReference(data[0]);
        }

    }

    const fetchFullReference = async (e?: any) => {
        let reference;
        if (e.target) {
            reference = references.filter((reference) => `${reference.title} | ${reference.type}` === e.target.value)[0]
        } else {
            reference = e;
        }
        const data = await getReference(reference._id);
        const fullRef = data['reference'];
        fullRef['content'] = data['segments'];
        selectReference(fullRef);
    }

    const setSegment = (e) => {
        selectSegment(() => segments.filter((segment) => segment._id === e.target.value)[0]);
    }

    return (
        <div className="createMode">
            <div className="notepad-settings">
                <PiArrowsCounterClockwise
                    className="reset"
                    fill={"var(--border-grey)"}
                    onClick={() => setResetKey(resetKey+1)}
                />
                {((mode === "cards" && method === "reference") || mode === "summary") &&
                <>{segments && segments.length > 0 && <select onChange={(e) => setSegment(e)}>
                    {segments.map((segment) => {
                        return <option value={segment._id}>{segment.key} | {segment.title}</option>
                    })}
                </select>}
                <select onChange={(e) => fetchFullReference(e)} >
                    {references && references.length > 0 && references.map((reference, i) => {
                        return <option key={i}>{reference.title} | {reference.type}</option>
                    })}


                </select></>}
                {method === "topic" && <input placeholder={"Insert topic.."} value={topic} onChange={(e) => setTopic(e.target.value)} />}
                {mode === "cards" && <select onChange={(e) => setMethod(e.target.value)}>
                    {["none","reference", "topic" ].map((item) => <option value={item}>{item}</option>)}
                </select>}
                <select onChange={(e) => setMode(e.target.value)}>
                    <option>cards</option>
                    <option>Feynman method</option>
                    <option>summary</option>
                </select>
            </div>
            <div className="notepad">
                {mode === "Feynman method" && <FeynmanMethod mode={"create"} key={resetKey} />}
                {mode === "cards" && <CardWorkspace key={resetKey} referenceID={selectedReference ? selectedReference._id : null} segmentID={selectedSegment ? selectedSegment._id : null} topic={topic.length > 0 ? topic : null}/>}
                {mode === "summary" && <SummaryWorkspace key={resetKey} />}
            </div>
        </div>
    )
}