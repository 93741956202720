import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const Graph = ({ nodes, links }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        // Set the dimensions and margins of the graph
        const width = 800, height = 600;

        // Append the svg object to the body of the page
        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height);

        // Initialize the links
        const link = svg.selectAll(".links")
            .data(links)
            .enter()
            .append("line")
            .style("stroke", "#aaa");

        // Initialize the nodes
        const node = svg.selectAll(".nodes")
            .data(nodes)
            .enter()
            .append("circle")
            .attr("r", 5)
            .style("fill", "#69b3a2");

        // Let's list the force we wanna apply on the network
        const simulation = d3.forceSimulation(nodes) // Force algorithm is applied to data.nodes
            .force("link", d3.forceLink() // This force provides links between nodes
                .id(function(d: any) { return d.id; }) // This provides the id of a node
                .links(links)) // and this the list of links
            .force("charge", d3.forceManyBody().strength(-400)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
            .force("center", d3.forceCenter(width / 2, height / 2)) // This force attracts nodes to the center of the svg area
            .on("tick", ticked);

        function ticked() {
            // Update node and link positions at every step of the force simulation
            link
                .attr("x1", function(d: any) { return d.source.x; })
                .attr("y1", function(d: any) { return d.source.y; })
                .attr("x2", function(d: any) { return d.target.x; })
                .attr("y2", function(d: any) { return d.target.y; });

            node
                .attr("cx", function(d: any) { return d.x; })
                .attr("cy", function(d: any) { return d.y; });
        }

        const drag = d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);

        svg.selectAll('.node')
            .data(nodes)
            .call(drag);

        function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
        }
    }, [nodes, links]); // Redraw graph when data changes

    return (
        <svg ref={svgRef}></svg>
    );
};

export default Graph;
