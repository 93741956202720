import {HorizontalMenu} from "../shared/HorizontalMenu";
import "../../css/problem_tracker.css";
import {useParams} from "react-router-dom";
import {CodingProblemDisplay} from "./CodingProblemDisplay";

export function ProblemTracker() {

    const params = useParams();

    return (
        <div className="problem-tracker">
            <HorizontalMenu
                links={[
                    {
                        name: "coding problems",
                        path: "/problem_tracker/coding"
                    },
                    {
                        name: "math problems",
                        path: "/problem_tracker/math"
                    },

                ]}
                alignment={"left"}
            />
            {params.hasOwnProperty("problem_type") && params.problem_type === "coding" && <CodingProblemDisplay />}
            {params.hasOwnProperty("problem_type") && params.problem_type === "math_problems"}
        </div>
    )
}