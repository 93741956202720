import "./form.css";
import {useEffect, useMemo, useState} from "react";
import {createReference, deleteRef, updateReference} from "../../api/reference_tracker";
import {useNavigate} from "react-router-dom";
import { PiArrowFatRightFill, PiArrowFatLeftFill } from "react-icons/pi";

interface FormPair {
    [key: string]: any;
    label: string;
    inputType: string;
    value?: string;
    options?: string[];
}

interface ContentObject {
    [key: string]: any;
    title: string;
    key: string;
    pageEnd: number;
    pageStart: number;
}

interface FormProps {
    formPairs: FormPair[];
    content: ContentObject[];
    id?:string;
}

interface FormData {
    [key: string] : any;
    cover?: string;
    title?: string;
}


export function Form({ formPairs, content, id }:FormProps) {
    const [ID, _] = useState(id);
    const [formData, setFormData] = useState<FormData>(formPairs.reduce((acc, pair) => ({...acc, [pair.label]: pair.value}), {}));
    const [contentData, setContentData] = useState<any[]>([...content]);
    const [currentPage, setCurrentPage] = useState(0);


    let navigate = useNavigate();

    useEffect(() => {
        console.log(id)
    },[])

    useEffect(() => {
        if (formData.segments > 0) {
            const newContent = Array.from({length: formData.segments}, (
                _, index) => ({
                _id: content.length >= index + 1 ? content[index]['_id'] : null,
                key: content.length >= index + 1 ? content[index]['key'] : "",
                unitStart: content.length >= index + 1 ? content[index]['unitStart'] : "",
                unitEnd: content.length >= index + 1 ? content[index]['unitEnd'] : "",
                title: content.length >= index + 1 ? content[index]['title'] : "",
                unit: content.length >= index + 1 ? content[index]['unit'] : "",
            }));
            setContentData(newContent);
        }

    },[formData.segments])

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        console.log(e.target.name);
        console.log(e.target.value);
    }

    const produceNewContentObject = () => {
        return {
            key: "",
            title: "",
            unitStart: -99,
            unitEnd: -99,
        }
    }

    const handleContent = (e, i: number) => {
        let contentCopy = contentData;
        if (i === contentCopy.length) {
            contentCopy.push(produceNewContentObject());
        }
        contentCopy[i] = {...contentCopy[i], [e.target.name]: e.target.value};
        setContentData([...contentCopy]);
    }

    const submit = async (e) => {
        const newRef = {...formData};
        newRef['authors'] = typeof newRef.authors === "string" ? newRef['authors'].split(";") : [];
        if (!ID) {
            console.log(newRef);
            let newContent = contentData;
            newContent = newContent.map((obj) => {
                obj.tags = typeof obj.tags === "string" ? obj['tags'].split(";") : [];
                delete obj._id;
                return obj;
            }).filter((obj) => obj.key);
            await createReference(formData, newContent).then((status) => status === 201 ? navigate('/reference_tracker') : alert("Failed to create ref"));
        } else {
            console.log(contentData)
            newRef['_id'] = ID;
            let newContent = contentData;
            newContent = newContent.map((obj) => {
                obj.tags = typeof obj.tags === "string" ? obj['tags'].split(";") : [];
                for (let prop of Object.keys(obj)) {
                    if (!obj[prop]) {
                        delete obj[prop];
                    }
                }
                return obj;
            }).filter((obj) => obj.key);
            console.log(newContent)
            await updateReference(newRef, newContent).then((status) => status === 201 ? navigate('/reference_tracker') : alert("Failed to update ref"));
        }
    }

    const segments = useMemo(() => {
        const emptySegments = Array.from({length: formData.segments}, (
            _, index) => ({
            key: contentData.length >= index + 1 ? contentData[index]['key'] : "",
            unitStart: contentData.length >= index + 1 ? contentData[index]['unitStart'] : "",
            unitEnd: contentData.length >= index + 1 ? contentData[index]['unitEnd'] : "",
            title: contentData.length >= index + 1 ? contentData[index]['title'] : "",
            unit: contentData.length >= index + 1 ? contentData[index]['unit'] : ""
        }));
        return emptySegments;
    }, [formData, contentData])

    const mainSection = useMemo(() => {
        return  <>
            {
                formPairs.map((pair: FormPair, index: number) => {
                    return <div
                            key={index}
                            className="form-pair">
                            <label>{pair.label}</label>
                                {
                                    (pair.inputType === "text" || pair.inputType === "imgUrl")
                                    &&
                                    <input
                                        type="text"
                                        name={pair.label}
                                        value={formData[pair.label]}
                                        onChange={(e) => handleChange(e)}
                                        // placeholder={pair.value}
                                    />
                                }
                                {
                                    pair.inputType === "dropdown"
                                    &&
                                    <select defaultValue={formData[pair.label]} name={pair.label} onChange={(e) => handleChange(e)}>
                                        {pair.options && pair.options.map((opt: string) => <option value={opt}>{opt}</option>)}
                                    </select>
                                }
                            </div>
                })
            }
        </>
    },[formPairs, formData]);

    const deleteReference = async () => {
        if (ID) await deleteRef(ID).then((status) => status !== 201 ? alert("Failed to delete reference.") : navigate("/reference_tracker"))
    }

    const segmentSection = useMemo(() => {
        return formData.segments &&
            <div className="content-container">
                <div className="content">
                    {segments.map((item: any, i: number) =>
                        <div className="segment">

                            {Object.entries(item).map(([ky, value]) =>
                            <div className="form-segment">
                                <label>{ky}</label>
                                <div className="segment-input">
                                    <input
                                        name={ky}
                                        // @ts-ignore
                                        value={value}
                                        onChange={(e) => handleContent(e, i)}
                                    />
                                </div>
                            </div>
                        ) }
                        </div>
                    )}

                </div>
            </div>
    },[segments]);

    const sections = [mainSection, segmentSection];


    return (
        <div className="form">
            <div className="form-main">
                <div className="form-content">{sections[currentPage]}</div>
                {formData.cover && formData.cover.startsWith("http") &&
                    // (formData.cover.endsWith("jpg") || formData.cover.endsWith("png") || formData.cover.endsWith("jpeg")) &&
                    <div className="cover-container">
                        <img
                            className="cover-img"
                            src={formData.cover.startsWith('http') ? formData.cover : `/images/${formData.cover.split('/')[formData.cover.split('/').length -1]}`}
                            alt={formData.title}
                        />
                    </div>
                }
            </div>
        <div className="container-pager">


            <div className="arrow-container container-left">
                {currentPage > 0 &&
                    <PiArrowFatLeftFill
                        className="arrow-left arrow"
                        onClick={() => setCurrentPage(currentPage-1)}
                    />
                }
            </div>
            <div className="arrow-container container-right">
                {formData.segments && currentPage < sections.length - 1  &&
                    <PiArrowFatRightFill
                        className="arrow arrow-right"
                        onClick={() => setCurrentPage(currentPage+1)}
                    />
                }
            </div>
        </div>
            <div className="btn-container btn">
                <button onClick={(e) => submit(e)}>submit</button>
                <button onClick={() => deleteReference()}>delete</button>
            </div>
        </div>
    )
}