import "./pomodoro.css";
import {TaskLog} from "./TaskLog";
import {PomodoroClock} from "./PomodoroClock";
import {TaskView} from "./TaskView";
import {useEffect, useState} from "react";
import {getAllTasks} from "../../../api/tasks";



export function Pomodoro() {
    const [activeTask, setActiveTask] = useState(null);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        retrieveAllTasks()
    },[]);

    const selectTask = (task) => {
        console.log(task)
        setActiveTask(task);
    }

    const retrieveAllTasks = async () => {
        const tasks = await getAllTasks();
        if (tasks.length > 0) {
            setTasks(tasks);
            setActiveTask(activeTask ? tasks.filter((task) => task._id === activeTask._id)[0] : null)
        }
    }

    const updateExercisesActiveTask = (e) => {
        setActiveTask((activeTask) => ({...activeTask, [e.target.name]: e.target.value}))
    }

    return (
        <div className="pomodoro">
            <PomodoroClock activeTask={activeTask} refresh={retrieveAllTasks} />
            <TaskLog tasks={tasks} selectTask={selectTask} activeTask={activeTask} refresh={retrieveAllTasks} />
            <TaskView task={activeTask} updateTask={updateExercisesActiveTask} />
        </div>
    )
}