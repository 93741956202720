import {extractKeywords} from "../../api/llm";
import {HorizontalMenu} from "../shared/HorizontalMenu";
import "../../css/notebook.css";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {CreateMode} from "./CreateMode";
import {ExplorerMode} from "./ExplorerMode";
import {ReadingMode} from "./ReadingMode";

export function Notebook() {
    const [createMode, setCreateMode] = useState(false);
    const [explorerMode, setExplorerMode] = useState(false);

    const params = useParams();

    return (
        <div className="notebook">
            <HorizontalMenu
                links={[
                    {
                        name: "explore",
                        path: "/notebook/explore"
                    },
                    {
                        name: "create",
                        path: "/notebook/create"
                    },
                    {
                        name: "read",
                        path: "/notebook/read"
                    }
                ]}
                alignment={"left"}
            />
            {params.hasOwnProperty("keyword") && params.keyword === "create" && <CreateMode />}
            {params.hasOwnProperty("keyword") && params.keyword === "explore" && <ExplorerMode />}
            {params.hasOwnProperty("keyword") && params.keyword === "read" && <ReadingMode />}
        </div>
    )
}
