import "./spaced_repetition_alarm.css";
import {useEffect, useState} from "react";
import {getCardsDue} from "../../api/spaced_repetition";
import {useLocation, useNavigate, useNavigation} from "react-router-dom";
import {motion} from "framer";

export function SpacedRepetitionAlarm() {
    const [cardsDue, setCardsDue] = useState(0);

    const nav = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes('/workspace/spaced_repetition')) {
            countCardsDue();
        }
    },[location.pathname])

    const countCardsDue = async () => {
        const cards = await getCardsDue();
        setCardsDue(cards.length);
    }

    return (
        <>
            {cardsDue > 0 &&
                <motion.div
                    tabIndex={0}
                    onClick={() => nav('/workspace/spaced_repetition')}
                    className="sr-alarm"
                    whileHover={{scale:1.08}}
                    whileTap={{scale:0.95}}
                >
                There are {cardsDue} cards due today!
            </motion.div>}
        </>
    )
}