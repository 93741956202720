import {apiBaseUrl} from "../index";

async function getCards(): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/card/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
        .then((response) => response.json());
}

async function getCardsDue(): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/card/due`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
        .then((response) => response.json());
}

async function processReview(card: any, review: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/card/process_review`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({card: card, review: review})
        },
    )
        .then((response) => response.status);
}


export {
    getCards,
    processReview,
    getCardsDue
}