import {apiBaseUrl} from "../index";

async function fetchReferences(): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/mdb/references/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
        },
    )
        .then((response) => response.json());
}

async function createReference(reference: any, segments: any = []): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/reference/create`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({reference: reference, segments: segments})
        },
    )
        .then((response) => response.status);
}

async function updateReference(reference: any, segments: any = []): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/reference/update`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({reference: reference, segments: segments})
        },
    )
        .then((response) => response.status);
}

async function getReference(id: string): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/reference/get`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({id: id})
        },
    )
        .then((response) => response.json());
}

async function createTracker(type: string, tracker: any, returnType?: string): Promise<any> {
    return await fetch(
         `${apiBaseUrl}/tracker/create`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({tracker: tracker, type: type})
        },
    )
        .then((response) => {
            if (returnType === "json") {
                return response.json();
            } else {
                return response.status
            }
        });
}

async function createQA(qa: any, questionTracker: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/qa/create`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                qa: qa, questionTracker: questionTracker
            })
        },
    )
        .then((response) => response.status);
}

async function getTrackers(segment: any): Promise<any> {
    console.log(segment)
    return await fetch(
        `${apiBaseUrl}/tracker/get`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(segment)
        },
    )
        .then((response) => response.json());
}

async function getFeynmanTrackers(): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/feynman_method/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
        .then((response) => response.json());
}

async function updateFeynmanTracker(id: string, tracker: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/feynman_method/update`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({tracker: tracker, id: id})
        },
    )
        .then((response) => response.status);
}

async function updateTracker(tracker: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/tracker/update`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tracker)
        },
    )
        .then((response) => response.status);
}

async function deleteRef(id: any): Promise<any> {
    console.log("sending..")
    return await fetch(
        `${apiBaseUrl}/reference/delete`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({id: id})
        },
    )
        .then((response) => response.status);
}

async function getPDF(reference) {
    return await fetch(
        `${apiBaseUrl}/article/pdf`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reference)
        },
    )
        .then((response) => response.blob())
}

export {
    fetchReferences,
    createReference,
    updateReference,
    getReference,
    createTracker,
    getTrackers,
    createQA,
    updateTracker,
    getFeynmanTrackers,
    updateFeynmanTracker,
    getPDF,
    deleteRef
}