import {apiBaseUrl} from "../index";

async function getAllTasks(): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/task/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        },
    )
        .then((response) => response.json());
}


async function createTask(task: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/task/create`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(task)
        },

    )
        .then((response) => response.status);
}

async function completeTask(task: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/task/update`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(task)
        },

    )
        .then((response) => response.status);
}

async function getAvailableSegments(referenceID: any, action: string): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/task/unavailable`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({referenceID: referenceID, action: action})
        },

    )
        .then((response) => response.json());
}

async function createDictionaryItem(dictionary_item: any): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/dictionary_item/create`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dictionary_item)
        },

    )
        .then((response) => response.status);
}

async function deleteTask(task): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/task/delete`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(task)
        },

    )
        .then((response) => response.status);
}



export {
    getAllTasks,
    createTask,
    completeTask,
    getAvailableSegments,
    createDictionaryItem,
    deleteTask
}