async function runCPPCode(code: string): Promise<any> {
    return await fetch(
        "https://www.fusionfrontier.tech/api/compiler/cpp",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({code: code})
        },
    )
        .then((response) => response.json());
}


export {
    runCPPCode
}