import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReferenceTracker} from "./components/reference_tracker/ReferenceTracker";
import { Provider } from 'react-redux';
import {store} from "./store";

export const environment = process.env.REACT_APP_ENV || 'production';
export const apiBaseUrl = environment === "development" ? "http://localhost:5000" : "https://www.fusionfrontier.tech/api";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

export {ReferenceTracker}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
