import "./spaced_repetition.css";
import { Card } from "./Card";
import {useEffect, useState} from "react";
import {getCards, getCardsDue, processReview} from "../../../api/spaced_repetition";
import {base64ToArrayBuffer} from "../../../utils/imageConversion";

export function SpacedRepetition() {
    const [cards, setCards] = useState([]);
    const [activeCard, setActiveCard] = useState(null);

    useEffect(() => {
        retrieveCards();
    },[])

    const retrieveCards = async () => {
        const data = await getCardsDue();
        console.log(cards);
        if (cards.length > 0) {
            const newCard = data.shift();
            setActiveCard(loadImage(newCard));
            setCards(data);
        }

    }

    const processEvaluation = async (score: number) => {
        // send score
        // remove card from top pile
        const oldCard = {...activeCard};
        console.log("Processing card..")
        await processReview(oldCard, {
            date: new Date(),
            score: score
        }).then((status) => {
            if (status !== 201) { alert("Card could not be processed"); return; }
            if (cards.length > 0) {
                const cardsCopy = [...cards];
                setActiveCard(loadImage(cardsCopy.shift()));
                setCards(cardsCopy);
            } else {
                setActiveCard(null);
            }

        })
    }

    const loadImage = (card: any) => {
        if (card.img && card.img.data) card.img.url = URL.createObjectURL(new Blob([base64ToArrayBuffer(card.img.data)], { type: activeCard.img.mimeType }));
        return card;
    }

    return (
        <div className="spaced-repetition">
            <div className="card-display">
                {activeCard && <Card
                        card={activeCard}
                        processEvaluation={processEvaluation}
                    />}
                {!activeCard && cards.length === 0 && <div className="no-cards-left">No more cards left...</div>}
            </div>
        </div>
    )
}