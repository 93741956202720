import {useEffect, useState} from "react";
import { PiArrowsLeftRight,PiFileImageDuotone } from "react-icons/pi";

function QA({ card, side, updateCard }) {

    useEffect(() => {
        console.log(card)
    },[card])

    const autoResizeTextArea = () => {
        const currentTextArea = document.getElementsByClassName('auto-resize');
        // @ts-ignore
        for (let element of currentTextArea) {
            element.style.height = 'auto';
            element.style.height = element.scrollHeight + 'px';
        }
    }

    return (
        <div className="QA">
            {side === "front" && <textarea
                value={card.question}
                onChange={(e) => {
                    updateCard(e);
                    autoResizeTextArea();
                }}
                name={"question"}
                spellCheck={"false"}
                className="auto-resize"
            />}
            {side === "back" && <textarea
                value={card.answer}
                onChange={(e) => {
                    updateCard(e);
                    autoResizeTextArea();
                }}
                spellCheck={"false"}
                name={"answer"}
                className="auto-resize"
            />}
            {card.img && card.img.mimeType &&
                <div
                    className={"img-container"}
                >
                    {!card.img.mimeType.includes("svg") &&
                        <img src={card.img.url} />
                    }
                    {card.img.mimeType.includes("svg") &&
                        <svg >
                            <image href={card.img.url}  />
                        </svg>


                    }
                </div>
            }
        </div>
    )
}



export function Card({ card, updateCard, side }) {
    const [id, setID] = useState(card._id || null);

    const autoResizeTextArea = () => {
        const currentTextArea = document.getElementsByClassName('auto-resize');
        // @ts-ignore
        for (let element of currentTextArea) {
            element.style.height = 'auto';
            element.style.height = element.scrollHeight + 'px';
        }
    }

    return (
        <div className="card">
            <div className="QA">
                {side === "front" && <textarea
                    value={card.front}
                    onChange={(e) => {
                        updateCard(e);
                        autoResizeTextArea();
                    }}
                    name={"front"}
                    placeholder={"This is the front.."}
                    spellCheck={"false"}
                    className="auto-resize"
                />}
                {side === "back" && <textarea
                    value={card.back.join("\n")}
                    onChange={(e) => {
                        updateCard(e);
                        autoResizeTextArea();
                    }}
                    spellCheck={"false"}
                    name={"back"}
                    placeholder={"This is the back.."}
                    className="auto-resize"
                />}
                {card.img && card.img.mimeType &&
                    <div
                        className={"img-container"}
                    >
                        {!card.img.mimeType.includes("svg") &&
                            <img src={card.img.url} />
                        }
                        {card.img.mimeType.includes("svg") &&
                            <svg >
                                <image href={card.img.url}  />
                            </svg>
                        }
                    </div>
                }
            </div>
        </div>
    )
}