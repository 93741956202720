import "./card_workspace.css";
import {useEffect, useState} from "react";
import {Card} from "./Card";
import {PiArrowFatLeftFill, PiArrowFatRightFill, PiArrowsLeftRight, PiFileImageDuotone} from "react-icons/pi";
import {processCards} from "../../../api/card_workspace";
import {getCards} from "../../../api/spaced_repetition";
import {fetchImage} from "../../../api/file_processing";
import {useSpring} from "react-spring";
import {base64ToArrayBuffer} from "../../../utils/imageConversion";
import {CardMetadata} from "./CardMetadata";


const generateDummyCard = (referenceID, segmentID, topic) => {
    return {
        _id: null,
        front: "",
        back: [""],
        img: {
            mimeType: null,
            url: null,
            data: null,
        },
        type: "standard",
        keywords: topic ? [topic] : [],
        field: null,
        referenceID: referenceID,
        segmentID: segmentID,
        lastModified: null
    }
}

export function CardWorkspace({ referenceID, segmentID, topic }) {
    const [cards, setCards] = useState([generateDummyCard(referenceID, segmentID, topic)]);
    const [currentCard, setCurrentCard] = useState(0);
    const [side, setSide] = useState("front");
    const [addImgWindow, setAddImgWindow] = useState(false);

    useEffect(() => {
        initializeCards();
    },[]);

    useEffect(() => {
        console.log(segmentID)
        initializeCards();
    },[topic, referenceID, segmentID]);

    const initializeCards = async () => {
        const data = await getCards();
        if (data.length > 0) {
            let filteredSet = referenceID ? data.filter((item) => item.referenceID === referenceID) : data;
            filteredSet = segmentID ? filteredSet.filter((item) => item.segmentID === segmentID) : filteredSet;
            filteredSet = topic ? filteredSet.filter((item) => item.keywords.includes(topic)) : filteredSet;
            if (filteredSet.length !== 0) {
                setCards(filteredSet.map((item) => {
                    return ({_id: item._id, front: item.front, back: item.back,
                        img: {
                            data: item.img ? item.img.data : null,
                            url: item.img.data ? URL.createObjectURL(new Blob([base64ToArrayBuffer(item.img.date)], { type: item.img.mime })) : null,
                            mimeType: item.img.mimeType
                        },
                        type: item.type,
                        keywords: item.keywords || [],
                        field: item.field,
                        referenceID: item.referenceID,
                        segmentID: item.segmentID,
                        lastModified: item.lastModified,
                    })
                }));
            }
        }
    }

    const addCard = () => {
        const len = cards.length;
        setCards([...cards, generateDummyCard(referenceID, segmentID, topic)]);
        setCurrentCard(len);
    }

    const flipCard = () => {
        side === "front" ? setSide("back") : setSide("front");
    }

    const submitCards = async () => {
        // add possibility for creating cards
        if (checkCards() === 0) {
            await processCards(cards.map((card) => {
                // @ts-ignore
                card['img'] = {data: card.img.data, mimeType: card.img.mimeType};
                console.log(card)
                card.segmentID = segmentID;
                card.referenceID = referenceID;
                return (card)
            })).then((status) => status !== 201 && alert("Cards couldn't be created and/or updated."))
        }
    }

    const checkCards = () => {
        for (let card of cards) {
            if (card.type === "keyboard" && card.keywords.length === 0) {
                alert(`Keywords should be provided for keyboard cards!\nLook at card with title "${card.front}"`);
                return 1;
            }
        }
        return 0;
    }

    const processImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            let cardsCopy = [...cards];
            const imageUrl = URL.createObjectURL(file);
            if (file) {
                // File has been uploaded, therefore needs to be parsed into base64 format
                const reader = new FileReader();
                reader.onload = function(e) {
                    const base64 = e.target.result;
                    cardsCopy[currentCard] = {...cardsCopy[currentCard], img: {
                            url: imageUrl, data: base64, mimeType: file.type
                        }};
                    setCards([...cardsCopy]);
                };
                reader.readAsDataURL(file);
            }
        } else {
            // An image URL has been provided, download image via proxy
            const imageUrl = e.target.value;
            const response = await fetchImage(imageUrl);
            let cardsCopy = [...cards];
            cardsCopy[currentCard] = {...cardsCopy[currentCard], "img": {
                    url: imageUrl, data: response.base64String, mimeType: response.mimeType
                }};
            setCards([...cardsCopy]);
        }
        // Close the window
        setAddImgWindow(false);
    }

    const updateCard = (e) => {
        if (e.target.name !== "img") {
            console.log(e.target.value.split("\n"));
            let cardsCopy = [...cards];
            if (e.target.name === "back") {
                cardsCopy[currentCard] = {...cardsCopy[currentCard], [e.target.name]: e.target.value.split("\n")};
            } else if (e.target.name === "keywords") {
                cardsCopy[currentCard] = {...cardsCopy[currentCard], [e.target.name]: e.target.value.split(";")};
            } else {
                cardsCopy[currentCard] = {...cardsCopy[currentCard], [e.target.name]: e.target.value};
            }
            setCards([...cardsCopy]);
        } else {
            processImage(e);
        }
    }

    return (
        <div className="cards-workspace">
            {addImgWindow && <div className="add-img-window">
                <button className="cls-window" onClick={() => setAddImgWindow(false)}>x</button>
                <div><label>image url</label></div>
                <input name={"img"} value={cards[currentCard].img.url} onChange={(e) => updateCard(e)}/>
                <div className="upload-img"><input type="file" name="img" alt='img' onChange={(e) => updateCard(e)}/></div>
            </div>}
            {currentCard > 0 && cards.length > currentCard && <PiArrowFatLeftFill fill={"var(--blue)"} className="arrow-cards left" onClick={() => setCurrentCard(currentCard-1)}/>}
                <div className="card-display">
                       <CardMetadata
                           card={cards[currentCard]}
                           setAddImgWindow={setAddImgWindow}
                           updateCardMetadata={updateCard}
                           addCard={addCard}
                           flipCard={flipCard}
                           side={side}
                       />
                    {cards.length > 0 &&
                        cards.length > currentCard &&
                        cards.map((card, i) => i === currentCard &&
                            <Card key={currentCard} card={cards[currentCard]} side={side} updateCard={updateCard} />)}
                </div>
            <div className="submit-card">
                <button
                    onClick={() => submitCards()}
                >
                    submit
                </button>
            </div>
            {cards && cards.length > currentCard &&
                <div className="statistics">
                card {currentCard+1} | set length {cards.length} | last modified on {cards[currentCard].lastModified ? cards[currentCard].lastModified.split("T")[0] : "unknown"}
            </div>}
            {currentCard < cards.length - 1 && <PiArrowFatRightFill fill={"var(--blue)"} className="arrow-cards right" onClick={() => setCurrentCard(currentCard+1)}/>}
        </div>
    )
}