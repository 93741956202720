import "../../css/reference_tracker.css";
import {HorizontalMenu} from "../shared/HorizontalMenu";
import {SearchBar} from "../shared/SearchBar";
import {useEffect, useMemo, useState, useRef} from "react";
import {fetchReferences, getReference} from "../../api/reference_tracker";
import {ReferenceCreation} from "./ReferenceCreation";
import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import {StatusUpdateWindow} from "./StatusUpdate";
import {motion} from "framer";
import {Tracker} from "./Tracker";
import {Display} from "./Display";

export function ReferenceTracker() {

    return (
        <div className="reference-tracker">
            <HorizontalMenu
                links={[
                    {name: "all", path: "/reference_tracker/all"},
                    {name: "books", path: "/reference_tracker/books"},
                    {name: "articles", path: "/reference_tracker/articles"},
                    {name: "research papers", path: "/reference_tracker/research_papers"},
                    {name: "videos", path: "/reference_tracker/videos"},
                    {name: "courses", path: "/reference_tracker/courses"},
                    {name: "websites", path: "/reference_tracker/websites"}
                ]}
                buttons={[{label: "+", path: "/reference_tracker/create"}]}
                alignment={"left"}
            />

            {/*{updateStatusWindow && <StatusUpdateWindow reference={updateStatusWindow} />}*/}

            <Display />

            {/*{referenceCreation && <ReferenceCreation referenceEdit={referenceEdit} />}*/}
        </div>
    )
}