import {Link, useLocation, useNavigation} from "react-router-dom";
import "./menu.css";
import {useEffect, useState} from "react";
import {motion} from "framer";

export function Menu() {
    const [activePath, setActivePath] = useState<string>("");

    const nav = useLocation();

    useEffect(() => {
        setActivePath(nav.pathname);
    },[nav.pathname])

    return (
        <div className="menu-main">
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (1)*0.4}
                }}
                style={{minWidth: 'max-content', padding: "0 2em"}}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("reference") ? "active-path" : "inactive-path"} `} to={"/reference_tracker"}>reference tracker</Link>
            </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (2)*0.4}
                }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("problem") ? "active-path" : "inactive-path"}`} to={"/problem_tracker"}>problem tracker</Link>
            </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (3)*0.4}
                }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("explorer") ? "active-path" : "inactive-path"}`} to={"/explorer"}>explorer</Link>
        </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                animate={{opacity:"100%", transition: {
                        duration: (4)*0.4}
                }}
                className={"menu-link"}
            >
            <Link className={`${activePath.includes("notebook") ? "active-path" : "inactive-path"}`} to={"/notebook"}>notebook</Link>
        </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                animate={{opacity:"100%", transition: {
                        duration: (4)*0.4}
                }}
                whileTap={{ scale: 0.95 }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("workspace") ? "active-path" : "inactive-path"}`} to={"/workspace"}>workspace</Link>
            </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (4)*0.4}
                }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("gallery") ? "active-path" : "inactive-path"}`} to={"/gallery"}>gallery</Link>
            </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (4)*0.4}
                }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("dictionary") ? "active-path" : "inactive-path"}`} to={"/dictionary"}>dictionary</Link>
            </motion.div>
            <motion.div
                whileHover={{scale:1.1}}
                initial={{opacity: 0}}
                whileTap={{ scale: 0.95 }}
                animate={{opacity:"100%", transition: {
                        duration: (4)*0.4}
                }}
                className={"menu-link"}
            >
                <Link className={`${activePath.includes("spaced_repetition") ? "active-path" : "inactive-path"}`} to={"/spaced_repetition"}>dictionary</Link>
            </motion.div>
        </div>
            )
}