import "./workspace.css";
import {HorizontalMenu} from "../shared/HorizontalMenu";
import {useLocation, useParams} from "react-router-dom";
import {Pomodoro} from "./pomodoro/Pomodoro";
import {SpacedRepetition} from "./spaced_repetition/SpacedRepetition";


export function Workspace() {

    const params = useParams()

    const paramsContains = (subspace: string) => {
        return params.hasOwnProperty("subspace") && params.subspace === subspace;
    }

    return (
        <>
            <HorizontalMenu
                links={[
                    {
                        name: "pomodoro",
                        path: "/workspace/pomodoro"
                    },
                    {
                        name: "reminders",
                        path: "/workspace/reminder"
                    },
                    {
                        name: "spaced repetition",
                        path: "/workspace/spaced_repetition"
                    }
                ]}
                alignment={"left"}
            />
    <div className="workspace">
            {paramsContains("pomodoro") && <Pomodoro />}
            {paramsContains("reminders") && <Pomodoro />}
            {paramsContains("spaced_repetition") && <SpacedRepetition />}
        </div>
        </>
    )
}