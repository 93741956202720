import {Form} from "../shared/Form";
import {useEffect} from "react";

interface ReferenceCreationProps {
    referenceEdit: any;
}


export function ReferenceCreation({referenceEdit}:ReferenceCreationProps) {

    useEffect(() => {
    },[])

    return (
        <div className="reference-creation">
            <Form formPairs={[
                {
                    label: "title",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.title : ""
                },
                {
                    label: "subtitle",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.subtitle : ""
                },
                {
                    label: "cover",
                    inputType: "imgUrl",
                    value: referenceEdit ? referenceEdit.cover : ""
                },
                {
                    label: "authors",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.authors.join(";") : ""
                },
                {
                    label: "yearPublished",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.yearPublished : ""
                },
                {
                    label: "type",
                    inputType: "dropdown",
                    value: referenceEdit ? referenceEdit.type : "book",
                    options: ["book", "research paper", "article", "website", "video", "course"]
                },
                {
                    label: "segments",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.segments : ""
                },
                {
                    label: "segmentUnit",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.segmentUnit : ""
                }
                ,
                {
                    label: "url",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.url : ""
                },
                {
                    label: "platform",
                    inputType: "text",
                    value: referenceEdit ? referenceEdit.platform : ""
                },
                {
                    label: "status",
                    inputType: "dropdown",
                    value: referenceEdit && ["inactive", "tracking", "archived"].includes(referenceEdit.status) ? referenceEdit.status : "inactive",
                    options: ["inactive", "tracking", "archived"]
                }
            ]}
                  content={referenceEdit ? referenceEdit.content : []}
                  id={referenceEdit ? referenceEdit._id : null}
            />
        </div>
    )
}