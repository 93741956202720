import {motion} from "framer";
import {useEffect, useState} from "react";
import {Tracker} from "./Tracker";
import {ReferenceDisplay} from "./ReferenceDisplay";
import {TrackerDisplay} from "./TrackerDisplay";
import {SearchBar} from "../shared/SearchBar";
import {ReferenceCreation} from "./ReferenceCreation";
import {useLocation, useNavigation, useParams} from "react-router-dom";
import {ArticleDisplay} from "./article_display/ArticleDisplay";

export function Display() {
    const [trackerItem, setTrackerItem] = useState(null);
    const [filter, setFilter] = useState("");
    const [referenceEdit, setReferenceEdit] = useState<any>(false);
    const [articlePdf, setArticlePdf] = useState(false);

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        if (params.hasOwnProperty("reference_type") && params.reference_type === "create") {
            setReferenceEdit(true);
        } else {
            setReferenceEdit(false);
        }
    },[params])

    const closeArticleDisplay = () => {setArticlePdf(false);}

    return (
        <motion.div className="display" id="display">
            {!articlePdf && <SearchBar processSearch={setFilter} />}
            {!referenceEdit && !articlePdf &&
                <ReferenceDisplay
                    key={location.pathname}
                    setTrackerItem={setTrackerItem}
                    filter={filter}
                    trackerItem={trackerItem}
                    setReferenceEdit={setReferenceEdit}
                    setArticle={setArticlePdf}
                />
            }
            {referenceEdit && !articlePdf &&
                <ReferenceCreation
                    referenceEdit={typeof referenceEdit === "object" ? referenceEdit : null}
                />
            }
            {articlePdf && <ArticleDisplay article={articlePdf} closeDisplay={closeArticleDisplay} />}
        </motion.div>
    )
}