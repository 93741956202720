import { PiNotePencilThin,PiFolderPlusThin } from "react-icons/pi";
import {useEffect, useRef, useState} from "react";
import "../../css/reference_display.css";
import {motion} from "framer";
import image from "./../../images/The_Drawbacks_and_Benefits_of_Solitude.png";
import {Tracker} from "./Tracker";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchReferences, getReference} from "../../api/reference_tracker";




export function ReferenceBlock({reference, index, setShowEdit, showEdit, editReference, updateStatus, setArticle }) {
    let mainDiv = useRef<HTMLDivElement>(null);
    const [rowSpan, setRowSpan] = useState<number>(10);
    const [menu, toggleMenu] = useState(false);

    useEffect(() => {
        setTimeout(() => calculatePosition(), 1000);
        console.log(image)
    }, [])

    useEffect(() => {
        if (mainDiv.current) calculatePosition();
    }, [mainDiv.current])

    const calculatePosition = () => {
        const gridItem = document.getElementById(`item-${index}`);
        const gridContainer = document.getElementById('reference-display');
        if (gridItem && gridContainer) {
            const rowSpan = (gridItem.clientHeight) / 1.9;
            setRowSpan(Math.ceil(rowSpan));
        }
    }

    const viewPDF = (e) => {
        console.log(e.target)
        if (reference.type === "article" && !(e.target instanceof SVGElement)){setArticle(reference)}
    }

    return (
        <>
            <motion.div
                tabIndex={0}
                key={index}
                ref={mainDiv}
                whileHover={{scale:1.05}}
                onClick={(e) => viewPDF(e)}
                onMouseEnter={() => toggleMenu(true)}
                onMouseLeave={() => toggleMenu(false)}
                initial={{
                    opacity: "0%"
                }}
                animate={{opacity: "100%", transition: {
                        delay: index * 0.01
                    }}}
                style={{gridRowEnd: `span ${rowSpan}`, cursor: reference.type === "article" ? "pointer": "normal"}}
                // @ts-ignore
                id={`item-${index}`}
                className={`${reference.type} tracker-reference-block`}
            >
                {menu &&
                    <motion.div
                        className="display-menu"
                        initial={{
                            opacity: "0%"
                        }}
                        animate={{
                            opacity: "100%",
                            // transition: {
                            //     duration:1
                            // }
                        }}
                        whileHover={{scale:1.1}}
                    >

                        <PiNotePencilThin
                            onClick={(e) => {e.preventDefault();editReference(reference)}}
                            style={{cursor: "pointer"}}
                            color={"white"}
                            className="edit-icon"
                        />
                        <div className="tag-menu">

                            <motion.div
                                className={`menu-tag status ${reference.status}`}
                                whileHover={{scale:1.1}}
                            >
                                {reference.status}
                            </motion.div>
                        </div>



                    </motion.div>
                }
                {showEdit === index && <div className="reference-menu">
                    <PiNotePencilThin
                        onClick={() => editReference(reference)}
                        style={{cursor: "pointer"}}
                    />
                    {reference.status === "tracking" && <PiFolderPlusThin

                    />}
                </div>}
                {reference.cover &&
                    <div>
                        <div className="img-border"></div>
                        <img className="cover" src={reference.cover.startsWith('http') ? reference.cover : `/images/${reference.cover.split("/")[reference.cover.split("/").length-1]}`} height={"300em"}/>
                    </div>}
            </motion.div>
        </>
    )
}