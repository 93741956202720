import "../../css/search_bar.css";
import {PiMagnifyingGlass} from "react-icons/pi";

interface SearchBarProps {
    processSearch: Function;
}

export function SearchBar({processSearch}: SearchBarProps) {


    return (
        <div className="search-bar">
            <input
                placeholder={"Find reference.."}
                onChange={(e) => processSearch(e.target.value)}
            />
            <button className="search-icon">
                <PiMagnifyingGlass />
            </button>
        </div>
    )
}