import {useEffect, useRef, useState} from "react";
import {processReview} from "../../../../api/spaced_repetition";

interface State {
    attemptsLeft: number;
    score: number;
    pressedKeys: Object;
}

export function Parallel({ processCard, card }) {
    const correctSequence = useRef<string[]>(card.back.map((key) => key.toLowerCase()));
    const currentState = useRef<State>({
        attemptsLeft: 3,
        score: 5,
        pressedKeys: {}
    });
    const [current, setCurrent] = useState<State>(currentState.current);

    useEffect(() => {
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);

        return () => {
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        }
    },[])

    useEffect(() => {
        if (Object.values(current.pressedKeys).every((val) => val === true) && correctSequence.current.length === Object.values(current.pressedKeys).length) {
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
            processCard(current.score);
        }
    },[current.pressedKeys])

    useEffect(() => {
        if (card) {
            let current = currentState.current;
            current = {...current, pressedKeys: card.back.reduce((obj, key) => {
                    obj[key.toLowerCase()] = false;
                    return obj;
                }, {})};
            currentState.current = current;
            setCurrent(current);
        }
    },[card]);

    const keyDown = (event) => {
        let current  = currentState.current;
        console.log(event.key.toLowerCase())
        console.log(correctSequence)
        if (correctSequence.current.includes(event.key.toLowerCase())) {
            event.preventDefault();
            current = {...current, pressedKeys: {...current.pressedKeys, [event.key.toLowerCase()]: true}};
        } else if (event.key !== "Enter") {
            console.log("Wrong attempt");
            current = {...current, attemptsLeft: current.attemptsLeft - 1};
        } else {
            if (current.attemptsLeft === 0) {
                window.removeEventListener('keydown', keyDown);
                window.removeEventListener('keyup', keyUp);
                processCard(0);
            }
        }
        console.log(current);
        currentState.current = current;
        setCurrent(current);
    }

    const keyUp = (event) => {
        let current = currentState.current;
        if (correctSequence.current.includes(event.key.toLowerCase())) {
            event.preventDefault();
            current = {...current, pressedKeys: {...current.pressedKeys, [event.key.toLowerCase()]: false}};
            currentState.current = current;
        }
    }

    return (
        <div tabIndex={0} onClick={(e) => {if (current.attemptsLeft === 0) { processCard(0) }}}>
            {current.attemptsLeft > 0 &&  <>
                {card.front}
                <div>press keys..</div>
                <div>
                    {currentState.current.attemptsLeft} attempts left.
                </div></>}
            {current.attemptsLeft === 0 &&
                <div>
                    Unfortunately you failed..<br />The answer was a parallel press of <code>{card.back.join(" + ")}</code>
                    <div>Press enter or click to move on..</div>
                </div>}
        </div>
    )
}