import {useEffect, useRef, useState} from "react";
import "../../css/reference_display.css";
import {motion} from "framer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchReferences, getReference} from "../../api/reference_tracker";
import {ReferenceBlock} from "./ReferenceBlock";
import {ReferenceCreation} from "./ReferenceCreation";

export function ReferenceDisplay({ setTrackerItem, trackerItem, filter, setReferenceEdit, setArticle }) {
    const [showEdit, setShowEdit] = useState(-1);
    const [references, setReferences] = useState([]);
    const [referenceCreation, setReferenceCreation] = useState(false);
    const [updateStatusWindow, setUpdateStatusWindow] = useState(null);
    const [tracker, toggleTracker] = useState(false);

    const location = useLocation()
    let navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        retrieveReferences();
    },[])

    useEffect(() => {
        if (!(params.hasOwnProperty("reference_type") && params.reference_type === "create")) {
            setReferenceCreation(false);
            retrieveReferences();
        } else {
            setArticle(null);
            setReferenceCreation(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        retrieveReferences();
    },[filter]);



    const retrieveReferences = async () => {
        let refs = await fetchReferences();
        if (params.hasOwnProperty("reference_type") && params.reference_type !== "create") {
            if (params.reference_type !== "all") {
                refs = refs.filter((reference: any) => reference.type === params.reference_type.replace("_", " ").slice(0,-1));
            }
        }

        if (params.reference_type !== "create" && filter.length > 0) {
            console.log("Filtering search..")
            refs = refs.filter((reference: any) => {
                return Object.entries(reference).filter(([key, value]) => {
                    if (!reference[key] || ["_id", "__v", "cover"].includes(key)) {
                        return false;
                    }
                    let comparisonKey = typeof reference[key] === "number" ? reference[key].toString() : Array.isArray(reference[key]) ? reference[key].join(";").toLowerCase() : reference[key].toLowerCase();
                    return comparisonKey.includes(filter)
                }).length > 0
            })
        }
        console.log(refs)
        setReferences([...refs]);
    }

    const fetchFullReference = async (reference:any) => {
        const data = await getReference(reference._id);
        const fullRef = data['reference'];
        fullRef['content'] = data['segments'];
        console.log(fullRef)
        return fullRef;
    }

    async function editReference(reference: any) {
        const fullRef = await fetchFullReference(reference)
        setReferenceEdit(fullRef);
        setReferenceCreation(true);
        navigate("/reference_tracker/create");
    }

    const enableTracker = async (reference: any) => {
        const fullRef = await fetchFullReference(reference);
        toggleTracker(true);
        setTrackerItem(fullRef);
    }


    function updateStatus(reference: any) {
        setUpdateStatusWindow(reference);
    }

    const closeTracker = () => {
        setTrackerItem(null);
        toggleTracker(false);
    }

    //
    // useEffect(() => {
    //     if (trackerItem){
    //         switchColumns("1/2", "2");
    //     } else if (trackerItem === null) {
    //         switchColumns("1/3");
    //     }
    // },[trackerItem])

    const activateTracker = async (reference: any) => {
        toggleTracker(true);
        const fullRef = await fetchFullReference(reference)
        console.log(fullRef)
        setTrackerItem(fullRef);
    }

    const switchColumns = (valueRefDisplay: string, valueTrackerDisplay?: string) => {
        const refDisplay = document.getElementById("reference-display");
        refDisplay.style.gridColumn = valueRefDisplay;
        if (valueTrackerDisplay) {
            const trackerDisplay = document.getElementById("tracker-display");
            trackerDisplay.style.gridColumn = valueTrackerDisplay;
        }

    }

    return (
        <motion.div
            className="reference-display"
            layout
            style={{gridColumn: trackerItem ? "1/2": "1/3"}}
            id="reference-display"
        >
            {references.map((reference: any, index:number) =>
                <ReferenceBlock
                    setArticle={setArticle}
                    key={index}
                    reference={reference}
                    setShowEdit={setShowEdit}
                    showEdit={showEdit}
                    index={index}
                    editReference={editReference}
                    updateStatus={updateStatus}
                />)}

        </motion.div>
    )
}