import {SearchBar} from "../shared/SearchBar";
import "../../css/explorer_mode.css"
import Graph from "../explorer/Graph";
import {HorizontalMenu} from "../shared/HorizontalMenu";

export function ExplorerMode() {
    const processSearch = () => {

    }

    return (
        <div className="explorerMode">
            <HorizontalMenu
                links={[
                    {
                        name: "explore",
                        path: "/notebook/explore"
                    },
                    {
                        name: "create",
                        path: "/notebook/create"
                    }
                ]}
                alignment={"left"}
            />
            <SearchBar processSearch={processSearch} />
            <Graph
                nodes={[
                    {id: 'Note 1', group:1},
                    {id: 'Note 2', group:1},
                ]}
                links={[
                    {source: 'Note 1', target: 'Note 2'}
                ]}
            />
        </div>
    )
}