import {useState, useEffect, useRef} from "react";
import {completeTask} from "../../../api/tasks";


export function PomodoroClock({ activeTask, refresh }) {
    const [countUp, setCountUp] = useState(false); // constrained or free i.e. w/ or w/o end time.
    const [isRunning, setIsRunning] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60*25);
    const [startTimeSession, setStartTimeSession] = useState(null);
    const [checkProgress, setCheckProgress] = useState(false);
    const [postSessionUnit, setPostSessionUnit] = useState(activeTask ? activeTask.currentUnit : null);
    const [extraTime, setExtraTime] = useState(0);
    const [formattedTime, setFormattedTime] = useState(null);
    const worker = useRef(null);
    const task = useRef(null);



    useEffect(() => {
        let newWorker;
        if (activeTask) task.current = activeTask;
        try {
            newWorker = new Worker(new URL("./pomodoro.worker.ts", import.meta.url));
        } catch (e) {
            console.log(e)
        }
        worker.current = newWorker;
        newWorker.onmessage = (event: any) => {
            setTimeLeft(event.data.timeLeft);
            setExtraTime(event.data.extraTime);
            setCountUp(event.data.direction === "up");
        };

        return () => newWorker.terminate();
    },[])

    useEffect(() => {
        console.log(activeTask)
        if (activeTask && !(task.current && (task.current.task === activeTask.task && task.current.referenceID === activeTask.referenceID && task.current.segmentIDs[0] === activeTask.segmentIDs[0]))) {
            if (activeTask.estimatedDuration) {
                let estDuration = activeTask.estimatedDuration;
                if (activeTask.logs && activeTask.logs.length > 0) {
                    for (let log of activeTask.logs) {
                        estDuration -= log.duration;
                    }
                }
                if (estDuration > 0) {
                    setTimeLeft(estDuration);
                    setStartTimeSession(estDuration);
                    worker.current.postMessage({action: "initialize", timeLeft: estDuration, extraTime: 0, direction: "down", startTime: estDuration, activeTask: true})
                } else {
                    setTimeLeft(0);
                    setCountUp(true);
                    setExtraTime(-1 * estDuration);
                    setStartTimeSession(0);
                    worker.current.postMessage({action: "initialize", timeLeft: 0, extraTime: -1 * estDuration, direction: "up", startTime: 0, activeTask: true})
                }
            } else if (activeTask.task) {
                setTimeLeft(25*60);
                setStartTimeSession(25*60);
                worker.current.postMessage({action: "initialize", timeLeft: 25*60, extraTime: 0, direction: "down", startTime: 25*60, activeTask: true})
            } else {
                setTimeLeft(25*60);
                setStartTimeSession(25*60);
                worker.current.postMessage({action: "initialize", timeLeft: 25*60, extraTime: 0, direction: "down", startTime: 25*60, activeTask: false})
            }
            task.current = activeTask;
            setCheckProgress(false);
        }

    }, [activeTask])

    useEffect(() => {
        console.log("TIME LEFT",timeLeft)
        formatTimeLeft()
    },[timeLeft, extraTime])

    const stopTimer = () => {
        worker.current.postMessage({action: "STOP"});
        setIsRunning(false);
        if (task.current && !task.current.task.toLowerCase().includes("exercise") && task.current.segmentIDs && task.current.segmentIDs.length === 1) {
            setCheckProgress(true);
        } else {
            generateTaskLog();
        }

    }
    const resetTimer = () => {
        setIsRunning(false);
        setTimeLeft(25 * 60); // Reset to 25 minutes
        worker.current.postMessage({action: "initialize", timeLeft: 25*60, extraTime: 0, direction: "down", startTime: 25*60, activeTask: !!task.current})
    };

    const formatTimeLeft = () => {
        const minutes = Math.floor((countUp ? extraTime : timeLeft) / 60);
        const seconds = (countUp ? extraTime : timeLeft) % 60;
        setFormattedTime(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    const generateTaskLog = async () => {
        if (task.current) {
            let newTask: any = {...task.current, currentUnit: postSessionUnit, logs: [...task.current.logs || [], {duration: startTimeSession - timeLeft + extraTime, date: new Date()}]};
            if (postSessionUnit && postSessionUnit >= newTask.segments[0].unitEnd) {
                newTask = {...newTask, completed:true};
            }
            delete newTask.reference;
            delete newTask.segment;

            await completeTask(newTask).then((status) => {
                setPostSessionUnit(null);
                setStartTimeSession(timeLeft);
                setCheckProgress(false);
                setCountUp(false);
                setExtraTime(0);
                status !== 201 ? alert("Failed to create log.") : refresh()
            });
        }

    }

    return (
        <div className="pomodoro-clock">

            {checkProgress && task.current &&  <div className="check-progress">What is the current {task.current.segments[0].unit || "unit"} you're on?
            <input type={"number"} onChange={(e) => setPostSessionUnit(Math.min(parseInt(e.target.value), task.current.segments[0].unitEnd))} defaultValue={task.current.currentUnit} value={postSessionUnit || task.current.currentUnit}/>
            <button onClick={(e) => generateTaskLog()}>update task</button></div>}
            <h2>Pomodoro clock</h2>
            {worker.current && formattedTime && <div className="clock">
                <div className="time-running">{formattedTime}</div>
                <button onClick={() => {worker.current.postMessage({action: "START"});setIsRunning(true)} } disabled={isRunning || !task.current}>Start</button>
                <button onClick={stopTimer} disabled={!isRunning}>Stop</button>
                <button onClick={resetTimer}>Reset</button>
            </div>}
        </div>
    )
}