import {PiArrowsLeftRight, PiFileImageDuotone} from "react-icons/pi";


export function CardMetadata({ addCard, flipCard, updateCardMetadata, card, setAddImgWindow, side }) {

    return (
        <div className="card-metadata">
            <div className="field">
                <label>field</label>
                <input name={"field"} value={card.field} onChange={(e) => updateCardMetadata(e)}/>
            </div>
            <div className="keywords">
                <label>keywords</label>
                <input name={"keywords"} value={card.keywords.join(";")} onChange={(e) => updateCardMetadata(e)}/>
            </div>
            <div className="tags">
                <label>type</label>
                <select value={card.type} onChange={(e) => updateCardMetadata(e)} name={"type"}>
                    {["standard", "command", "keyboard shortcut", "keyboard", "image"].map((type) => <option value={type}>{type}</option>)}
                </select>
            </div>
            <div className="tags">
                <label>side</label>
                <span style={{fontFamily: 'monospace', color: '#c9beff'}}>{side}</span>
            </div>
            <div className="add-card">
                <button
                    onClick={() => addCard()}
                >+</button>
            </div>
            <div className="flip-card">
                <PiArrowsLeftRight color={"var(--link-grey)"} style={{cursor: "pointer"}} onClick={() => flipCard()}/>
            </div>
            <div className="add-img">
                <PiFileImageDuotone color={"var(--link-grey)"} onClick={() => setAddImgWindow(true)}/>
            </div>
        </div>
    )
}