import {useEffect, useState} from "react";
import {base64ToArrayBuffer} from "../../../utils/imageConversion";
import {KeyboardShortCutCard} from "./keyboard_shortcut/KeyboardShortCutCard";
import {motion} from "framer";
import {CommandCard} from "./command/CommandCard";
import {KeyboardCard} from "./keyboard/KeyboardCard";


function EvaluationBar({ processEvaluation }) {

    const scores = [
        {
            label: "perfect",
            score: 5,
        },
        {
            label: "almost perfect",
            score: 4
        },
        {
            label: "needs improvement",
            score: 3
        },
        {
            label: "very incomplete",
            score:2
        },
        {
            label: "fail",
            score: 0
        }
    ];

    return (
        <div className="evaluation-bar">
            {scores.map((obj) => <button onClick={(e) => {e.preventDefault();processEvaluation(obj.score)}}>{obj.label}</button>)}
        </div>
    )
}


export function Card( { card, processEvaluation }) {
    const [isFlipped, setIsFlipped] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((key) => key + 1);
        console.log(card)
    },[card])

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        <motion.div
            // exit={{x:"-100vw"}}
            className="card-container"
        >
                {card.type === "keyboard shortcut" && <KeyboardShortCutCard card={card} processCard={processEvaluation} />}
                {card.type === "command" && <CommandCard card={card} processCard={processEvaluation} key={card._id} />}
                {card.type === "keyboard" && <KeyboardCard card={card} processCard={processEvaluation} key={card._id} />}
                {/* Keyboard and command cards require special processing of answer */}
                {!["keyboard shortcut", "keyboard", "command"].includes(card.type) &&
                    <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleClick}>
                        <div className="card-front">
                            {/* Content for the front of the card */}
                            {card && <div>{card.front}</div>}
                        </div>
                        <div className="card-back">
                            {/* Content for the back of the card */}
                            {card && card.back}
                            {card.img.url &&
                                <div>
                                    <img height={"250em"} src={card.img.url} />
                                </div>}
                            <EvaluationBar processEvaluation={processEvaluation} />
                        </div>
                    </div>}
        </motion.div>
    )
}