import {CalendarPreview} from "./CalendarPreview";
import {ReferenceTrackerPreview} from "./ReferenceTrackerPreview";
import {SpacedRepetitionPreview} from "./SpacedRepetitionPreview";

export function Dashboard() {



    return (
        <div className="dashboard">
            <CalendarPreview />
            <ReferenceTrackerPreview />
            <SpacedRepetitionPreview />
        </div>
    )
}
