import {useEffect, useRef, useState} from "react";

export function KeyboardCard({ card, processCard }) {
    const answer = useRef({
        answer: card.back.join("\n"),
        keywords: card.keywords
    })
    const currentState = useRef({
        attemptsLeft: 3,
        score: 5,
        answer: ""
    })
    const npt = useRef(null);
    const [current, setCurrent] = useState(currentState.current);
    const [shake, setShake] = useState(false);

    useEffect(() => {
        if (npt.current) {
            npt.current.value = "";
            npt.current.focus();
        }
    },[])

    const updateAnswer = (e) => {
        let crrnt = currentState.current;
        crrnt.answer = e.target.value;
        currentState.current = crrnt;
        setCurrent(crrnt);
    }


    const containsAllKeywords = (sentence, keywords) => {
        // Convert the sentence to lowercase to make the search case-insensitive
        const lowerCaseSentence = sentence.toLowerCase();

        // Check if every keyword is included in the sentence
        return keywords.every(keyword => lowerCaseSentence.includes(keyword.toLowerCase()));
    }


    const processAnswer = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            let crrnt = currentState.current;
            if (containsAllKeywords(crrnt.answer, answer.current.keywords)) {
                processCard(crrnt.score);
            } else {
                setShake(true);
                // Remove the animation class after it completes
                setTimeout(() => setShake(false), 500);
                crrnt.score = crrnt.score - 1;
                crrnt.attemptsLeft = crrnt.attemptsLeft - 1;
                currentState.current = crrnt;
                setCurrent(crrnt);
            }
        }
    }


    return (
        <div className="keyboard-card" tabIndex={0} onClick={(e) => {if (current.attemptsLeft === 0) { processCard(0) }}}>
            {current.attemptsLeft > 0 &&  <>
                {card.front}
                <div>
                    <textarea
                    ref={npt}
                    onChange={(e) => updateAnswer(e)}
                    className={`${shake ? 'shake-animation' : ''}`}
                    onKeyDown={(e) => processAnswer(e)}
                /></div>
                <div>
                    {currentState.current.attemptsLeft} attempts left.
                </div></>}
            {current.attemptsLeft === 0 &&
                <div>
                    Unfortunately you failed..<br />The answer was {card.back}
                    <div>Press enter or click to move on..</div>
                </div>}
        </div>
    )
}