import {Link} from "react-router-dom";
import "./horizontal_menu.css";
import {ButtonProps} from "./Button";
import {Button} from "./Button";
import {motion} from "framer";
import {useEffect, useState} from "react";

interface HorizontalMenuLink {
    [key:string]: any;
    name: string;
    path: string;
}

interface HorizontalMenuProps {
    links: HorizontalMenuLink[];
    buttons?: ButtonProps[];
    alignment: string;
}

export function HorizontalMenu ({links, buttons, alignment="left"}: HorizontalMenuProps) {
    const [activeLink, setActiveLink] = useState("all");


    return (
        <div className="horizontal-menu">
            <div className="link-section-menu">
                {links.map((link: HorizontalMenuLink, index: number) => {
                return <motion.div
                    style={{display: "inline-block"}}
                    whileHover={{scale:1.1}}
                    initial={{opacity: 0}}
                    animate={{opacity:"100%", transition: {
                    duration: (index+1)*0.4}
                    }}
                >
                    <Link
                    className={`align-${alignment} horizontal-link ${activeLink === link.name ? "active-link" : "inactive-link"}`}
                    to={link.path}
                    key={index}
                    onClick={(e) => setActiveLink(link.name)}
                >
                        {link.name}
                </Link>
                </motion.div>
            })}
            </div>
            <div className="button-section-menu">
                {buttons &&
            buttons.map((btn: ButtonProps, index: number) => <Button key={index} path={btn.path} label={btn.label} />)}
            </div>
        </div>
    )
}