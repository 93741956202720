import "../../css/explorer.css";
import {useEffect} from "react";
import Graph from "./Graph";
const graph = {
    nodes: [
        {id: 'Note 1', group:1},
        {id: 'Note 2', group:1},
    ],
    links: [
        {source: 'Note 1', target: 'Note 2'}
    ]
}

export function Explorer() {

    useEffect(() => {

    },[])

    return (
        <div className="explorer">
            <div id="keyword-graph-container">
                <Graph
                    nodes={[
                    {id: 'Note 1', group:1},
                    {id: 'Note 2', group:1},
                    ]}
                    links={[
                        {source: 'Note 1', target: 'Note 2'}
                    ]}
                />
            </div>
            <div className="img-container">
                <img
                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Sucrose_molecule.svg/1200px-Sucrose_molecule.svg.png"}
                    alt={"molecule"}
                    height={"300em"}
                />
            </div>
            <div className="text-container">
                <div className="text-header">
                    Molecule
                </div>
                <div className="text-main">


                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque non nisi a augue finibus venenatis. Cras posuere euismod diam, id ultrices lorem dapibus auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at turpis non est tincidunt pellentesque eu vel nisi. Pellentesque tincidunt porta faucibus. Quisque nec tempus mi. Suspendisse commodo, sapien id efficitur egestas, odio purus convallis sapien, vitae porttitor neque eros non libero. Pellentesque eleifend metus ac turpis interdum feugiat. Sed non ante at turpis placerat tempor ut ut leo. Cras posuere nulla quis risus euismod, sit amet volutpat lacus ultrices. Cras ut sapien porta nulla imperdiet malesuada. Sed pharetra purus vitae eros condimentum gravida.<br /><br />

                    Nunc vitae mattis magna. Nullam tincidunt tristique purus vitae vulputate. Sed aliquam sapien at justo fringilla gravida. Integer ultrices, tortor at rhoncus porta, orci lacus congue nisi, id commodo massa odio a dui. Mauris id nulla in nulla tincidunt venenatis ut nec dui. Cras hendrerit laoreet ex, a suscipit tortor porttitor non. Vivamus vehicula sem maximus, porta ligula in, facilisis nisl. Fusce laoreet dapibus arcu commodo volutpat. Curabitur et blandit lacus. Etiam vel mollis ante. Sed at faucibus diam. Cras vehicula tempor dictum. Ut bibendum, est eu sodales auctor, tellus augue tincidunt ligula, aliquet pretium ipsum felis ut felis. Donec dignissim massa lectus, at tempus massa facilisis ac.<br /><br />

                    Interdum et malesuada fames ac ante ipsum primis in faucibus. Suspendisse mollis mi at magna auctor, eget varius nulla consequat. In non ornare purus. Praesent commodo laoreet massa, vitae tincidunt nibh imperdiet non. Nullam orci leo, vulputate at eros in, imperdiet euismod dui. In ultricies congue lacinia. Quisque lacus justo, imperdiet eu placerat ut, posuere vel nisi. Cras congue lacus varius justo molestie pharetra. Mauris nec sollicitudin augue. Mauris eget porta mauris. Maecenas eleifend convallis tellus. Mauris mi nulla, ultrices et nisi at, egestas semper elit. Etiam quis odio a diam interdum placerat nec ut tellus.<br /><br />

                    Maecenas vitae neque dui. Maecenas facilisis fringilla tortor pharetra blandit. Sed velit felis, mattis in odio vitae, luctus tincidunt urna. Nulla sit amet pellentesque elit. Donec ut tincidunt mauris. Quisque sit amet orci porttitor, tristique nisi et, laoreet diam. In nec fringilla lacus. Suspendisse a metus justo. Nullam hendrerit sem ac finibus luctus. Aenean hendrerit eu dolor vitae congue. Aenean pulvinar, nibh euismod rutrum dignissim, nunc sem feugiat elit, a blandit libero sapien vel purus. Morbi elementum in orci eget tincidunt. Suspendisse rhoncus rutrum tincidunt. Etiam ornare ultricies enim eget malesuada.<br /><br />

                    Curabitur ut eros non justo ornare fringilla at ac elit. Curabitur quis sapien viverra velit laoreet porta quis eu lorem. Sed bibendum felis eget viverra placerat. In non libero porttitor augue sollicitudin egestas eget sed nisl. Curabitur accumsan dui a quam porta ullamcorper. Nunc odio mauris, finibus a dignissim id, blandit finibus mauris. Donec blandit mauris quis sem porta, nec imperdiet mi lacinia.<br /><br />

                    Fusce vestibulum metus et velit finibus congue. Vivamus turpis metus, suscipit sit amet volutpat at, posuere at purus. Pellentesque scelerisque, purus eu pulvinar feugiat, justo lectus aliquet augue, ac consectetur ante magna dapibus magna. Praesent magna odio, sagittis nec accumsan sit amet, ultricies ultricies elit. Phasellus sed odio sit amet mauris cursus posuere. Suspendisse potenti. Phasellus at malesuada massa. Aliquam erat volutpat. Donec egestas aliquet placerat.<br /><br />

                    Pellentesque eleifend vestibulum lacinia. Duis scelerisque tellus a metus interdum, ut pharetra eros mattis. Aenean fermentum dui sit amet est maximus, eu sollicitudin est faucibus. Suspendisse varius sed felis eu vestibulum. Fusce ultricies sed neque in aliquet. Curabitur nunc libero, rhoncus id blandit non, porttitor eu nisl. Quisque id finibus libero. In dapibus efficitur tempus. Nam pretium ligula tellus. Etiam aliquet sapien eu efficitur interdum. Vivamus egestas eu nulla non pretium. Quisque pellentesque accumsan dapibus. In consectetur quis ex nec sagittis. Donec vulputate porta volutpat.<br /><br />

                    Nullam semper ut metus non viverra. Curabitur arcu ex, dictum nec maximus non, ornare accumsan ante. Pellentesque sed facilisis turpis, in gravida ipsum. Nullam porttitor blandit facilisis. Vivamus vitae quam nec purus sagittis mollis a et elit. Pellentesque eu lacus massa. Duis et eros consectetur, porttitor neque sed, eleifend metus. Cras a turpis a ex efficitur porttitor. Nam pulvinar felis et lacus euismod, id lacinia nulla facilisis. Praesent in scelerisque elit. Ut vulputate nunc in ipsum ullamcorper mollis. Nulla vitae tempor ipsum. Aliquam malesuada id odio vel rutrum. Vivamus tempus aliquet urna. Suspendisse nisi eros, elementum eget sodales vitae, lacinia nec ligula. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                </div>
            </div>
        </div>
    )

}