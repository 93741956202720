import {useEffect, useState} from "react";
import {createTracker} from "../../../api/reference_tracker";
import {createDictionaryItem} from "../../../api/tasks";


export function TaskView({ task, updateTask }) {
    const [feynmanQuestion, setFeynmanQuestion] = useState("");
    const [dictionaryItem, setDictionaryItem] = useState("");

    useEffect(() =>{
        console.log(task)
    })

    const addFeynmanTracker = async () => {
        await createTracker("feynman", {question: feynmanQuestion, levels: []}).then((status) => status !== 201 ? alert("Failed to create tracker.") : setFeynmanQuestion(""))
    }

    const addDictionaryItem = async () => {
        await createDictionaryItem({term: dictionaryItem, creationDate: new Date()}).then((status) => status !== 201 ? alert("Failed to create tracker.") : setDictionaryItem(""))
    }


    return (
        <div className="task-view">

            <div className="add-feynman">
                Add Feynman tracker
                <input value={feynmanQuestion} onChange={(e) => setFeynmanQuestion(e.target.value)} />
                <button onClick={() => addFeynmanTracker()}>add</button>
            </div>

            <div className="add-term">
                Add dictionary item
                <input value={dictionaryItem} onChange={(e) => setDictionaryItem(e.target.value)} />
                <button onClick={() => addDictionaryItem()}>add</button>
            </div>

            {task && task.task.toLowerCase().includes("exercise") &&
                <div className="exercise-tracking">
                    <div className={"pair"}>
                        <label>completed exercises</label>
                        <input onChange={(e) => updateTask(e)} value={task.completedExercises || ""} name={"completedExercises"}/>
                    </div>
                    <div className={"pair"}>
                        <label>current exercise</label>
                        <input onChange={(e) => updateTask(e)} value={task.currentExercise || ""} name={"currentExercise"}/>
                    </div>
                </div>}
            {task && task.currentUnit &&
                <div className="exercise-tracking">
                    {task.segments.length > 0 ?? task.segments[0].unit} {task.currentUnit}
                </div>}

        </div>
    )
}
