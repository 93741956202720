import {HorizontalMenu} from "../shared/HorizontalMenu";
import {useState} from "react";
import {runCPPCode} from "../../api/compilers";

export function CodingProblemDisplay() {
    const [code, setCode] = useState("");

    const submitCode = async () => {
        console.log(code);
        await runCPPCode(code).then((res) => console.log(res))
    }

    return (
        <div className="coding-problem-display">
            <textarea value={code} onChange={(e) => setCode(e.target.value)}>
            </textarea>
            <button onClick={submitCode}>submit</button>
        </div>
    )
}