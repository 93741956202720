import {Parallel} from "./Parallel";
import {Sequence} from "./Sequence";
import {useEffect, useState} from "react";

export function KeyboardShortCutCard({ card, processCard }) {
    const [sequenceKey, setSequenceKey] = useState(null);
    const [parallelKey, setParallelKey] = useState(null);

    useEffect(() => {
        if (card.back.length > 1) {
            setParallelKey(`prl-${card._id}`);
        } else if (card.back.length === 1) {
            setSequenceKey(`seq-${card._id}`);
        }
    },[card])

    return (
        <div className="keyboard-shortcut" tabIndex={0}>
            {card.back.length > 1 && <Parallel processCard={processCard} card={card.back.length > 1 ? card : null} key={parallelKey} />}
            {card.back.length === 1 && <Sequence processCard={processCard} card={card} key={sequenceKey} />}
        </div>
    )
}