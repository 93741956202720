import {HorizontalMenu} from "../shared/HorizontalMenu";


function LetterBlock() {

    return (
        <div className="letter-block">
            <div className="letter">

            </div>
            <div>

            </div>
        </div>
    )
}



export function Dictionary(){



    return (
        <div className="dictionary">
            <HorizontalMenu
                links={[
                    {
                        name: "Danish",
                        path: "/dictionary/Danish"
                    },
                    {
                        name: "Dutch",
                        path: "/dictionary/Dutch"
                    },
                    {
                        name: "English",
                        path: "/dictionary/English"
                    },
                    {
                        name: "Japanese",
                        path: "/dictionary/Japanese"
                    },
                ]}
                alignment={"left"}
            />
        </div>
    )
}