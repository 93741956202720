import {HorizontalMenu} from "../shared/HorizontalMenu";
import {useParams} from "react-router-dom";
import {CSSPenDisplay} from "./CSSPenDisplay";

export function Gallery () {


    const params = useParams();

    return (
        <div className="gallery">
            <HorizontalMenu
                links={[
                    {
                        name: "frameworks",
                        path: "/gallery/frameworks"
                    },
                    {
                        name: "CSS pens",
                        path: "/gallery/CSS_pens"
                    },
                    {
                        name: "design patterns",
                        path: "/gallery/design_patterns"
                    }
                ]}
                alignment={"left"}
            />
            {params.hasOwnProperty("display") && params.display === "CSS_pens" && <CSSPenDisplay />}
        </div>
    )
}