import {useEffect, useState} from "react";
import {getPDF} from "../../../api/reference_tracker";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import "./article_display.css"
import {PiArrowFatLeftFill, PiArrowFatRightFill} from "react-icons/pi";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



export function ArticleDisplay({ article, closeDisplay }) {
    const [fileUrl, setFileUrl] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(0)

    useEffect(() => {
        if (article) {
            getArticlePDFUrl()
        }
    },[])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const getArticlePDFUrl = async () => {
        console.log("reached")
        const blob = await getPDF(article);
        const url = window.URL.createObjectURL(blob);
        console.log(url)
        setFileUrl(url);
    }

    return (
        <div className="article-display">
            <div tabIndex={0}><button className={"close-pdf"} onClick={() => closeDisplay()}>x</button></div>
            {pageNumber > 1 && <PiArrowFatLeftFill
                className="arrow arrow-right"
                style={{marginRight: "1em", fill: "orange"}}
                onClick={() => setPageNumber(pageNumber-1)}
            />}
            {fileUrl && <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>}
            {pageNumber < numPages && <PiArrowFatRightFill
                className="arrow arrow-right"
                style={{marginLeft: "1em", fill: "orange"}}
                onClick={() => setPageNumber(pageNumber+1)}
            />}
        </div>
    )
}