import {useEffect, useRef, useState} from "react";

export function Sequence({ processCard, card }) {
    const correctSequence = useRef<string[]>(card.back[0].split(""));
    const currentState = useRef({
        attemptsLeft: 3,
        score: 5,
        pressedKeys: []
    });
    const [current, setCurrent] = useState(currentState.current);

    useEffect(() => {
        window.addEventListener('keydown', listenForKeys);

        return () => {
            console.log("Unmounting parallel")
            window.removeEventListener('keydown', listenForKeys);
        }
    },[]);

    useEffect(() => {
        if (current.pressedKeys.length === correctSequence.current.length) {
            processCard(current.score);
        }
    },[current])

    const listenForKeys = (e) => {
        let crrnt = currentState.current;
        console.log(e.key + " " + correctSequence.current[crrnt.pressedKeys.length]);
        if ((!["Shift", "Control", "Alt"].includes(e.key) && e.key !== correctSequence.current[crrnt.pressedKeys.length]) && crrnt.attemptsLeft > 0) {
            console.log("Wrong attempt")
            crrnt = {...crrnt, attemptsLeft: crrnt.attemptsLeft - 1, pressedKeys: [], score: crrnt.score - 1};
        } else if (e.key === correctSequence.current[crrnt.pressedKeys.length]) {
            crrnt = {...crrnt, pressedKeys: [...crrnt.pressedKeys, e.key]};
        } else if (e.key === "Enter" && crrnt.attemptsLeft === 0) {
            processCard(0);
        }
        currentState.current = crrnt;
        setCurrent(crrnt);
    }

    return (
        <div tabIndex={0} onClick={() => {if (current.attemptsLeft === 0) { processCard(0); }}}>
            {currentState.current.attemptsLeft > 0 &&  <>
                {card.front}
                <div>press keys..</div>
                <div>
                    {currentState.current.attemptsLeft} attempts left.
                </div></>}
            {currentState.current.attemptsLeft === 0 &&
                <div>
                    Unfortunately you failed.. <br />The answer was a sequential press of <code>{correctSequence.current.join("")}</code>
                    <div>
                        Please press Enter or click to continue..
                    </div>
                </div>}
        </div>
    )
}