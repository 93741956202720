import React from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Dashboard} from "./components/dashboard/Dashboard";
import {ReferenceTracker} from "./components/reference_tracker/ReferenceTracker";
import {Explorer} from "./components/explorer/Explorer";
import {Menu} from "./components/shared/Menu";
import {Notebook} from "./components/notebook/Notebook";
import {Workspace} from "./components/workspace/Workspace";
import {Gallery} from "./components/gallery/Gallery";
import {Dictionary} from "./components/dictionary/Dictionary";
import {ProblemTracker} from "./components/problem_tracker/ProblemTracker";
import {SpacedRepetitionAlarm} from "./components/shared/SpacedRepetitionAlarm";

function App() {
  return (
    <div className="app">

        <Router>
            <Menu />
            <SpacedRepetitionAlarm />
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/calendar" element={<Dashboard />} />
                <Route path="/spaced_repetition" element={<Dashboard />}/>
                <Route path="/reference_tracker" element={<ReferenceTracker />}/>
                <Route path="/reference_tracker/:reference_type" element={<ReferenceTracker />}/>
                <Route path="/problem_tracker" element={<ProblemTracker />}/>
                <Route path="/problem_tracker/:problem_type" element={<ProblemTracker />}/>
                <Route path="/explorer" element={<Explorer />}/>
                <Route path="/notebook" element={<Notebook />}/>
                <Route path="/notebook/:keyword" element={<Notebook />}/>
                <Route path="/workspace" element={<Workspace />}/>
                <Route path="/workspace/:subspace" element={<Workspace />}/>
                <Route path="/gallery" element={<Gallery />}/>
                <Route path="/gallery/:display" element={<Gallery />}/>
                <Route path="/dictionary" element={<Dictionary />}/>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
