import {apiBaseUrl} from "../index";


async function processCards(cards: any[]): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/card/process`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({cards: cards})
        },
    )
        .then((response) => response.status);
}

export {
    processCards
}