import {useEffect, useState} from "react";
import {completeTask, createTask, deleteTask, getAllTasks, getAvailableSegments} from "../../../api/tasks";
import { PiCardholderDuotone,  PiClockDuotone } from "react-icons/pi";
import {fetchReferences, getReference} from "../../../api/reference_tracker";
import {motion} from "framer";



function DailyStatistics({ activeTaskCount, completedTaskCount, timeSpentToday, totalTimeSpent }) {

    return (
        <div className="daily-statistics">
            <div className="task-count statistic-container">
                <div className="statistic">{activeTaskCount}</div> active task(s)
            </div>
            <div className="completed-task-count statistic-container">
                <div className="statistic">{completedTaskCount}</div> tasks completed
            </div>
            <div className="time-spent-today-count statistic-container">
                <div className="statistic">{Math.round(timeSpentToday/60)}</div> minutes spent today
            </div>
            <div className="total-time-spent-count statistic-container">
                <div className="statistic">{Math.round(totalTimeSpent/60)}</div> minutes spent in total
            </div>
        </div>
    )
}

function AddTask({ refresh }) {
    const [currentTask, setCurrentTask] = useState("");
    const [referenceID, setReferenceID] = useState(null);
    const [segmentIDs, setSegmentIDs] = useState([]);
    const [includeReference, setIncludeReference] = useState(false);
    const [references, setReferences] = useState([]);
    const [segments, setSegments] = useState([]);
    const [estimatedDuration, setEstimatedDuration] = useState(null);
    const [includeEstimatedDuration, setIncludeEstimateDuration] = useState(false);
    const [currentUnit, setCurrentUnit] = useState(null);

    useEffect(() => {
       if (currentTask.length > 0 && referenceID && segments.length > 0) filterSegments();
    },[currentTask])

    useEffect(() => {
        if (includeReference) {
            getReferences();
        } else {
            setReferenceID(null);
            setSegmentIDs([]);
        }
    },[includeReference]);

    useEffect(() => {
        if (referenceID) {
            getSegments();
        }
    },[referenceID]);


    useEffect(() => {
        // Always use the unit of the first segment
        if (segmentIDs.length === 1) {
            setCurrentUnit(() => {
                return segments.filter((segment) => segment._id === segmentIDs[0])[0].unitStart
            })
        }
    },[segmentIDs])

    const getReferences = async () => {
        // Get all references and order alphabetically by title
        const data = await fetchReferences();
        if (data.length > 0) {
            setReferences(data.sort((a, b) => a.title.localeCompare(b.title)));
        }
    }

    const reset = () => {
        // Reset entire form
        setIncludeReference(false);
        setSegmentIDs([]);
        setReferenceID(null);
        setCurrentTask("");
        setReferences([]);
        setSegments([]);
        setIncludeEstimateDuration(false);
        setEstimatedDuration(null);
        refresh();
    }

    const getSegments = async () => {
        // Based on specified action in tasks, segments are filtered based on which are already tied to a specified tracker associated with the action
        // i.e. "Read [...]" looks for a reading tracker associated with the reference, and then looks at the tasks already tied to the tracker
        const data = await getReference(referenceID);
        filterSegments(data)

    }

    const filterSegments = async (ref?: any) => {
        const unfilteredSegments = ref ? ref.segments : segments;
        if (unfilteredSegments.length > 0) {
            const availableSegments = await getAvailableSegments(referenceID, currentTask.toLowerCase());
            if (availableSegments.length > 0) {
                setSegments(availableSegments);
                // Initialize segmentIDs with first available segment ID
                setSegmentIDs([availableSegments[0]._id]);
                setCurrentUnit(availableSegments[0].startUnit || null)
            } else {
                setSegments([]);
                setSegmentIDs([]);
                setCurrentUnit(null)
            }

        } else {
            setSegments([]);
            setSegmentIDs([]);
        }
    }

    const addTask = async (e) => {
        if (e.key === "Enter" || !e.key) {
            let task = {
                task: currentTask,
                creationDate: new Date(),
                referenceID: referenceID,
                segmentIDs: segmentIDs,
                estimatedDuration: estimatedDuration > 0 ? estimatedDuration * 60 : segmentIDs ? 120 * (segments.filter((segment) => segmentIDs.includes(segment._id)).map((segment) => segment.unitEnd - segment.unitStart))[0] : null,
                startUnit: currentUnit,
                currentUnit: currentUnit
            }
            console.log(task)
            await createTask(task).then((status) => status !== 201 ? alert("Failed to create task.") : reset())
        }
    }

    return (
        <>
            <div className="add-task">
                <h1 style={{cursor: "pointer"}} onClick={(e) => addTask(e)}>+</h1>
                <input
                    value={currentTask}
                    onKeyDown={(e) => addTask(e)}
                    onChange={(e) => setCurrentTask(e.target.value)}
                    placeholder={"Add a task to 'Tasks' and press [Enter] to save."}
                />
                <PiClockDuotone
                    className="add-duration"
                    onClick={(e) => setIncludeEstimateDuration(!includeEstimatedDuration)}
                />
                <PiCardholderDuotone
                    height={"3em"}
                    className="add-reference"
                    onClick={(e) => setIncludeReference(!includeReference)}
                />
            </div>
            {(includeReference || includeEstimatedDuration) &&
                <div className="task-options">
                    {references.length > 0 &&
                <div className="add-reference-window">
                    <select onChange={(e) => setReferenceID(e.target.value)}>
                        {references.map((reference, index) => <option key={index} value={reference._id} id={reference._id}>{reference.title} | {reference.authors.length > 0 ? reference.authors.join(", ") + " | " : ""} {reference.type}</option>)}
                    </select>
                </div>
            }
                {includeReference && segments.length > 0 &&
                    <div className="add-segment-window">
                        <select multiple={true} onChange={(e) => {
                            // @ts-ignore
                            setSegmentIDs([...e.target.selectedOptions].map((html) => html.value))
                        }}>
                            {segments.map((segment, index) => <option value={segment._id} key={index} id={segment._id}>{segment.key} | {segment.title}</option>)}
                        </select>
                        <input type={'checkbox'} onChange={(e) => {
                                if (e.target.checked) setSegmentIDs(segments.map((segment) => segment._id));
                                if (!e.target.checked) setSegmentIDs([])
                        }
                        }/> Comprise all segments in one task
                    </div>
                }
                    {includeEstimatedDuration &&
                        <div className="add-duration-window">
                            Estimated duration in minutes <input
                            onChange={(e) => setEstimatedDuration(e.target.value)}
                            type={"number"}
                        />    {segmentIDs ? `${segments.filter((segment) => segment._id === segmentIDs[0]).map((segment) => `for ${segment.unitEnd - segment.unitStart} ${segment.unit + "s" || " units"} to consume`)[0]}` : "" }
                        </div>}
            </div>}

        </>
    )
}

function TaskDisplay({ tasks, refresh, selectTask, activeTask }) {
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [showCompletedTasks, setShowCompletedTasks] = useState(false)

    useEffect(() => {
        filterTasks();
        console.log(tasks)
    }, [tasks, showCompletedTasks]);

    const filterTasks = () => {
        const completedTasks = tasks.filter((task) => task.completed);
        const incompleteTasks = tasks.filter((task) => !task.completed);
        if (showCompletedTasks) {
            setFilteredTasks([...incompleteTasks, ...completedTasks])
        } else {
            setFilteredTasks([...incompleteTasks]);
        }
    }


    const updateTask = async (e, task) => {
        const updatedTask = {...task, completed: true};
        if (updatedTask.reference) delete updatedTask['reference'];
        if (updatedTask.segment) delete updatedTask['segment'];
        await completeTask(updatedTask).then((status) => status === 201 ? refresh() : alert("Failed to update task"))
    }

    const formulateTaskHeading = (task) => {
        if (task.segments && task.segments.length > 1) {
            return `${task.task} ${task.segments.length} segments of ${task.reference.title}`;
        } else if (task.segments && task.segments.length === 1) {
            let main = `${task.task} ${task.segments[0].key} of ${task.reference.title}`;
            if (task.reference.type === "book") {
                main += ` by ${task.reference.authors.join(", ")}`;
            }
            return main;
        }
        return task.task;
    }

    const dropTask = async (task : any) => {
        await deleteTask(task).then((status) => status !== 500 && refresh());
    }

    return (
        <div className="task-display">
            <div className="heading">
                <h2>Tasks</h2>
                <div className="show-completed-tasks">
                    Show completed tasks
                    <input onChange={(e) => setShowCompletedTasks(!showCompletedTasks)} type={"checkbox"} />
                </div>
            </div>

            <div className="tasks">
                {filteredTasks.length > 0 && filteredTasks.map((task, i) => {
                    return <motion.div
                        whileHover={{scale:1.02, background: "pink"}}
                        key={task._id}
                        className={`task ${task.completed && "completed "} ${activeTask && task._id === activeTask._id && "active-task"}`} onClick={(e) => selectTask(task)}>
                        <input onChange={(e) => updateTask(e, task)} type={"radio"} checked={task.completed} />
                        {!task.completed && <>{formulateTaskHeading(task)}</>}
                        {task.completed && <s><i>{formulateTaskHeading(task)}</i></s>}
                        <button
                            onClick={() => dropTask(task)}
                            className="delete-task">x</button>
                    </motion.div>
                })}
            </div>
        </div>
    )
}

export function TaskLog({ tasks, selectTask, activeTask, refresh }) {


    return (
        <div className="task-log">
            <h2>Today</h2>
            <DailyStatistics
                activeTaskCount={tasks.filter((task) => !task.completed).length}
                completedTaskCount={tasks.filter((task) => task.completed).length}
                totalTimeSpent={tasks.map((task) => {
                    let totalTime = 0;
                    task.logs.map((log) => {
                        totalTime += log.duration;
                    })
                    return totalTime;
                }).reduce((acc, cv) => acc + cv, 0)}
                timeSpentToday={tasks.map((task) => {
                    let totalTimeToday = 0;
                    task.logs.map((log) => {
                        if (log.date.split("T")[0] === new Date().toISOString().split("T")[0]) totalTimeToday += log.duration;
                    })
                    return totalTimeToday
                }).reduce((acc, cv) => acc + cv, 0)}
            />
            <AddTask refresh={refresh} />
            <TaskDisplay tasks={tasks} refresh={refresh} selectTask={selectTask} activeTask={activeTask} />
        </div>
    )

}