import {apiBaseUrl} from "../index";

async function fetchImage(url:string): Promise<any> {
    return await fetch(
        `${apiBaseUrl}/proxy`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({url : url})
        },
    )
        .then((response) => response.json());
}

export {
    fetchImage
}