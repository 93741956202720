import {useEffect} from "react";
import "../../css/css_pen_display.css";

export function CSSPenDisplay() {


    useEffect(() => {

    },[])


    const loadPens = async () => {

    }

    return (
        <div className='css-pen-display'>
        </div>
    )
}