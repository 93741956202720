import {useEffect, useRef, useState} from "react";

export function CommandCard({ card, processCard }) {
    const correctAnswer = card.back[0];
    const currentState = useRef({
        score: 5,
        attemptsLeft: 3,
        answer: ""
    });
    const [current, setCurrent] = useState(currentState.current);
    const [shake, setShake] = useState(false);
    const npt = useRef(null);

    useEffect(() => {
        if (npt.current) {
            npt.current.value = "";
            npt.current.focus();
        }
    },[])

    const processAnswer = (e) => {
        let crrnt = currentState.current;
        console.log(correctAnswer)
        if (e.key === "Enter" && crrnt.attemptsLeft !== 0) {
            if (crrnt.answer === correctAnswer) {
                processCard(crrnt.score);
            } else {
                crrnt.attemptsLeft = crrnt.attemptsLeft - 1;
                // Make input shake
                setShake(true);
                // Remove the animation class after it completes
                setTimeout(() => setShake(false), 500);
                crrnt.score = crrnt.score - 1;
                e.target.value = "";
            }
        }
        currentState.current = crrnt;
        setCurrent(crrnt);
    }

    const updateAnswer = (e) => {
        let current = currentState.current;
        current.answer = e.target.value;
    }

    return (
        <div className="command-card" tabIndex={0} onClick={(e) => {if (current.attemptsLeft === 0) { processCard(0) }}}>
                {current.attemptsLeft > 0 &&  <>
                    {card.front}
                    <div><input
                        ref={npt}
                        onChange={(e) => updateAnswer(e)}
                        className={`${shake ? 'shake-animation' : ''}`}
                        onKeyDown={(e) => processAnswer(e)}
                    /></div>
                    <div>
                        {currentState.current.attemptsLeft} attempts left.
                    </div></>}
                {current.attemptsLeft === 0 &&
                    <div>
                        Unfortunately you failed..<br />The answer was <code>{card.back}</code>
                        <div>Press enter or click to move on..</div>
                    </div>}
        </div>
    )
}