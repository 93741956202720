function base64ToArrayBuffer(base64) {
    const base64Cleaned = base64.split(';base64,').pop();
    const binaryString = window.atob(base64Cleaned); // Decode base64
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export {
    base64ToArrayBuffer
}